import * as React from "react"
import Helmet from "react-helmet";
import Layout from '../components/Layout'
import { Link } from "gatsby"

import classnames from 'classnames'

import '../components/moon.scss'

import config from '../config'

import Box from "@mui/material/Box";
import {BlackBack, Box4Title, SubTitleEnBox, SubTitleWhiteBox, BtnNext} from '../components/Common/Base'
import { makeStyles }from "@material-ui/styles";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { seoTitle, seoDescription, browserTitle, serviceData, serviceFeature, keywords} from '../data/index'

import Stack from "@mui/material/Stack";

import VideoJs from '../components/Video'
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles({
    box1: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      height: '56.25vw',
      minHeight: 420
    },
    flexCenter: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    mb25: {
      marginBottom: 25,
    },
    boxRelative: {
      position: 'relative',
    },
    videoBack: {
      position: 'relative',
      zIndex: 1,
      marginLeft: '0%',
      marginTop: '0%',
      width: '100%',
      height: '100%',
      verticalAlign: 'top'
    },
    box4Content: {
      position: 'absolute',
      zIndex: 5,
      width: '100%',
      height: '100%',
    },
  });

const LinkBox1 = styled(Link)(({theme}) => {
  return {
    display: 'flex',
    marginTop: 25,
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #04D94F',
    color: '#04D94F',
    textDecoration: 'none',
    width: 200,
    height: 30,
    borderRadius: 15,
    // [theme.breakpoints.up('sm')]: {
    //   width: 300,
    //   height: 50,
    //   borderRadius: 25,
    // },
    // [theme.breakpoints.up('md')]: {
    //   width: 400,
    //   height: 64,
    //   borderRadius: 32,
    // }
  }
});

const CountNum = styled('div')(({theme}) => {
  return {
    color: '#04D94F',
    fontSize: '36px',
    textAlign: 'center',
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: '28px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
    }
  }
});


const ServiceItem = styled('div')(({theme}) => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    background: '#0D3140',
    borderRadius: 4,
    height: 188,
    textAlign: 'center',
    '& .img': {
      width: 50,
      height: 50,
      marginBottom: 10,
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    },
    '& .div': {
      height: 30,
      fontSize: '12px',
      width: '80%'
    },
    [theme.breakpoints.up('sm')]: {
      height: 280,
      '& .img': {
        width: 70,
        height: 70
      },
      '& .div': {
        height: 45,
        fontSize: '14px',
      }
    },
    [theme.breakpoints.up('md')]: {
      height: 360,
      '& .img': {
        width: 100,
        height: 100
      },
      '& .div': {
        height: 60,
        fontSize: '18px',
      }
    }
  }
})


// markup
const IndexPage = (props) => {
  const { location } = props;
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  // console.log(matches);

  const eleShow = React.useRef();

  const handleNextClick = () => {
    eleShow.current && eleShow.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  };

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500)
  }, []);

  return (
    <Layout location={location}>
      <Helmet>
        <meta name="title" content={seoTitle} />
        <meta name="description" content={seoDescription} />
        <meta name="keywords" content={'赑玄阁, 气象服务, 模拟风, 防灾减灾, 天气模拟, 天气预测'} />
        <title>{browserTitle}</title>
      </Helmet>

      {
        loading ? (
          <div
            style={{
              zIndex: 99999,
              position: 'fixed',
              top: 0,
              bottom: 0,
              left: 0,
              width: '100%',
              backgroundColor: '#000000',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <div className="moon" />
            <div className="moon-loading-txt">LOADING</div>
          </div>
        ) : null
      }

      {/* box1 */}
      <Box
        className={classes.box1}
      >
        <Box className={classes.box4Content}>
          <Container
            maxWidth="xl"
            style={{
              position: 'relative',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="h2"
              align="center"
              gutterBottom
              sx={{
                fontWeight: 'bold',
                fontSize: {
                  xs: '32px',
                  sm: '5vw',
                  lg: '56px',
                },
                textShadow: '1px 1px 5px #000',
              }}
            >
              尖端的计算技术<br />
              超分辨率的气象服务
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              gutterBottom
              sx={{
                fontSize: {
                  xs: '16px',
                  sm: '2.5vw',
                  lg: '28px',
                },
                textShadow: '1px 1px 5px #000',
              }}
            >
              Cutting-edge computing technology to produce
              <br /> ultra fine-scale weather services
            </Typography>
            <LinkBox1 to={'/products/city3d'}>
              了解更多 visit
            </LinkBox1>
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                textAlign: 'center',
                zIndex: 8,
                pt: {
                  xs: 4,
                  md: 8,
                  lg: 16,
                }

              }}
            >
              <Box
                component="img"
                alt=""
                onClick={handleNextClick}
                src={require('../images/common/icon_down.png').default}
                sx={{
                  cursor: 'pointer',
                  width: {
                    xs: '35px',
                    sm: '45px',
                    md: '60px'
                  },
                }}
              />
            </Box>
          </Container>
        </Box>
        <VideoJs
          imagePath={require('../images/index/02.jpg').default}
          options={{
            autoplay: true,
            controls: false,
            // responsive: true,
            fluid: true,
            preload: 'auto',
            sources: [{
              src: config.MEDIA_ROOT + 'index/1118.m3u8',
              // src: 'https://media.bixuange.com/static/video/index/02.m3u8',
              // src: require('../images/index/02.mp4').default,
              type: 'application/x-mpegURL',
              // type: 'video/mp4'
            }]
          }}
        />
        {/*<video*/}
          {/*className={classnames(classes.videoBack)}*/}
          {/*style={{*/}
            {/*objectFit: 'cover',*/}
          {/*}}*/}
          {/*// src={require('../images/index/02.mp4').default}*/}
          {/*src={'https://media.bixuange.com/static/video/index/02.m3u8'}*/}
          {/*autoPlay="autoPlay"*/}
          {/*loop="loop"*/}
          {/*muted*/}
        {/*>*/}
          {/*您的浏览器不支持 video。*/}
        {/*</video>*/}
        <BlackBack />
      </Box>
      {/* box2 */}
      <Box
        ref={eleShow}
        sx={{
          pt: {
            xs: 0,
            lg: 10
          },
          pb: {
            xs: 6,
            sm: 12,
            md: 18,
            lg: 24
          }
        }}
      >
        <Container maxWidth="xl">
          <Grid
            container
            spacing={2}
            alignItems="stretch"
            columns={{ xs: 6, md: 12 }}
            sx={{
              pb: {
                xs: 4,
                sm: 8,
                md: 12,
                lg: 20
              }
            }}
          >
            <Grid
              item
              xs={6}
              md={6}
              sx={{
                display: {
                  xs: 'none',
                  md: 'flex',
                },
                // justifyContent: 'flex-end',
              }}
              className={classes.flexCenter}
            >
              <img alt="超时空分" width={'60%'} src={require('../images/index/b2.png').default} />
            </Grid>
            <Grid
              item
              xs={6}
              md={6}
              sx={{
                flexDirection: 'column'
              }}
              className={classes.flexCenter}
            >
              <SubTitleWhiteBox variant="subtitle1" gutterBottom>
                赑玄阁在高时间及空间分辨率的本地天气服务方面设立了一个新的标准。
                通过使用尖端的计算技术，提供精细化的天气服务和高精度的天气模拟。
                重要的应用领域包括能源、防灾减灾、双碳、空气污染、建筑和研究。
                我们根据客户的需求定制产品，
                用于受天气影响的各领域。
              </SubTitleWhiteBox>

              <SubTitleEnBox variant="subtitle1" gutterBottom>
                The Big Pavilion sets a new standard in hyper-local weather services.
                By using cutting-edge computing technology we produce fine-scale weather
                services and weather simulations with high accuracy. Important application
                domains include Energy, disaster prevention and mitigation, carbon emissions,
                air pollution, construction industries and atmospheric research.
                Our products can be fully tailored to customers’ needs and can be used forany
                application domain where local weather phenomena are of importance.
              </SubTitleEnBox>
            </Grid>
          </Grid>
          <Grid className={classes.mb25} container spacing={2} alignItems="stretch"  columns={{ xs: 6, md: 12 }}>
            {
              serviceData.map((item, index) => {
                return (
                  <Grid
                    key={'server-item-' + index}
                    item
                    xs={3}
                    md={3}
                    sx={{
                      flexDirection: 'column'
                    }}
                    className={classes.flexCenter}
                  >
                    <SubTitleWhiteBox
                      align="center"
                      variant="subtitle1"
                    >
                      {item.title}
                    </SubTitleWhiteBox>
                    <SubTitleEnBox
                      variant="body2"
                      align="center"
                    >
                      {item.titleEn}
                    </SubTitleEnBox>
                    <CountNum>
                      {item.count}
                    </CountNum>
                  </Grid>
                )
              })
            }
          </Grid>
        </Container>
      </Box>
      {/* box3 */}
      <Box
        className={classes.boxRelative}
        sx={{
          height: '56.25vw',
        }}
      >
        <Box className={classes.box4Content}>
          <Container
            maxWidth="xl"
            style={{
              position: 'relative',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <Box4Title
              variant="h4"
              align="center"
            >
              超高速风模拟 High Optimization for Simulation
            </Box4Title>
            <SubTitleWhiteBox
              variant="subtitle1"
              gutterBottom
              align="center"
            >
              通过定制的模拟方法，实现超大城市尺度下微环境大风模拟的业务应用
            </SubTitleWhiteBox>
            <SubTitleEnBox
              variant="subtitle1"
              align="center"
              sx={{
                mb: {
                  xs: '3vw',
                  md: '7vw',
                },
              }}
            >
              Operational application of microenvironmental gale simulation at mega-city scale through customized simulation methods
            </SubTitleEnBox>
            <Box
              alt=""
              sx={{
                width: {
                  xs: '15px',
                  sm: '20px',
                  md: '30px',
                },
              }}
              src={require('../images/index/mouse.png').default}
              component="img"
            />
            <Typography
              variant="subtitle1"
              gutterBottom
              align="center"
              sx={{pt: '10px'}}
            >
              拖动鼠标查看实时效果
            </Typography>
            <Typography
              variant="body1"
              align="center"
            >
              Click and drag mouse to view the live effect
            </Typography>
          </Container>
        </Box>
        <SimulationBox
          src={'/simulation/'}
          // src={'http://120.25.211.128/simulation/'}
        />
      </Box>
      {/* box4 */}
      <Box className={classes.boxRelative}>
        <Box className={classes.box4Content}>
          <Container maxWidth="xl" style={{position: 'relative', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <Stack
              direction={{
                xs: 'column',
                md: 'row'
              }}
              spacing={0}
            >
              <Box4Title
                variant="h4"
              >
                超高空间分辨率
              </Box4Title>
              <Box4Title
                variant="h4"
              >
                High Spatial Resolution
              </Box4Title>
            </Stack>
            <Box sx={{width: '80%'}}>
              <SubTitleWhiteBox variant="subtitle1" gutterBottom>
                我们不断在提升空间分辨率精准度, 从1000米现已提升至2.5米。
              </SubTitleWhiteBox>
              <SubTitleEnBox variant="subtitle1" gutterBottom>
                We are constantly improving our spatial resolution, from 1000 meters to 2.5 meters.
              </SubTitleEnBox>
            </Box>
          </Container>
        </Box>
        <VideoJs
          imagePath={require('../images/index/04.jpg').default}
          options={{
            autoplay: true,
            controls: false,
            // responsive: true,
            fluid: true,
            sources: [{
              src: config.MEDIA_ROOT + 'index/04.m3u8',
              // src: 'https://media.bixuange.com/static/video/index/02.m3u8',
              // src: require('../images/index/02.mp4').default,
              type: 'application/x-mpegURL',
              // type: 'video/mp4'
            }]
          }}
        />
        {/*<video*/}
          {/*className={classnames(classes.videoBack)}*/}
          {/*style={{*/}
            {/*objectFit: 'cover',*/}
          {/*}}*/}
          {/*// src={require('../images/index/04.mp4').default}*/}
          {/*src={'https://media.bixuange.com/static/video/index/04.m3u8'}*/}
          {/*autoPlay="autoPlay"*/}
          {/*loop="loop"*/}
          {/*muted*/}
        {/*>*/}
          {/*您的浏览器不支持 video。*/}
        {/*</video>*/}
      </Box>
      {/* box5 */}
      <Box
        className={classes.boxRelative}
      >
        <Box
          className={classes.box4Content}
          style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}
          sx={{
            pb: {
              xs: 1,
              sm: 2,
              md: 4,
              lg: 8,
            }
          }}
        >
          <Stack
            direction={{
              xs: 'column',
              md: 'row'
            }}
            spacing={0}
            justifyContent="center"
            alignItems="center"
            sx={{
              pb: {
                xs: 1,
                sm: 2,
                md: 3,
              }
            }}
          >
            <Box4Title
              variant="h4"
              align="center"
            >
              超高时间分辨率
            </Box4Title>
            <Box4Title
              variant="h4"
              align="center"
            >
              High Temporal Resolution
            </Box4Title>
          </Stack>
          <Box>
            <SubTitleWhiteBox variant="subtitle1" align="center" gutterBottom>
              我们不断在提升时间分辨率精准度, 将10分钟一帧的数据序列填补至秒级。
            </SubTitleWhiteBox>
            <SubTitleEnBox variant="subtitle1" align="center" gutterBottom>
              Evolving accuracy, filling data sequences of 10 minutes a frame to the second level
            </SubTitleEnBox>
          </Box>
        </Box>
        <VideoJs
          imagePath={require('../images/index/05.jpg').default}
          options={{
            autoplay: true,
            controls: false,
            // responsive: true,
            fluid: true,
            sources: [{
              src: config.MEDIA_ROOT + 'index/05.m3u8',
              type: 'application/x-mpegURL',
            }]
          }}
        />
        {/*<video*/}
          {/*className={classnames(classes.videoBack, classes.mb25)}*/}
          {/*// src={require('../images/index/05.mp4').default}*/}
          {/*src={'https://media.bixuange.com/static/video/index/05.m3u8'}*/}
          {/*autoPlay="autoPlay"*/}
          {/*loop="loop"*/}
          {/*muted*/}
        {/*>*/}
          {/*您的浏览器不支持 video。*/}
        {/*</video>*/}
      </Box>
      {/* Box6 */}
      <Container
        maxWidth="xl"
        sx={{
          pt: {
            xs: 4,
            md: 8,
            lg: 16,
          },
          pb: {
            xs: 4,
            md: 8,
            lg: 16,
          },
        }}
      >
        <Grid
          container
          spacing={4}
          sx={{
            pb: 10,
            pt: 10
          }}
        >
          <Grid
            item
            xs={12}
            md={4}
          >
            <Stack
              direction={{
                xs: 'column',
              }}
              spacing={0}
            >
              <Box4Title
                variant="h4"
                sx={{textAlign: {xs: 'center', md: 'left'}}}
              >
                服务特色
              </Box4Title>
              <Box4Title
                variant="h4"
                sx={{textAlign: {xs: 'center', md: 'left'}}}
              >
                Service Features
              </Box4Title>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
          >
            <SubTitleWhiteBox
              variant="subtitle1"
              gutterBottom
              sx={{
                textAlign: {xs: 'center', md: 'left'},
                mb: 2
              }}>
              提供可以业务化的高空间、高时间分辨率的城市微环境服务。在更大的空间时间尺度上，提供全球的长期分析服务。
              通过多年的经验，我们的系统在设计之初就考虑并开发具备应对在恶劣天气形式下：观测数据缺失、模型输出频次提高、
              计算负荷倍增、服务请求高并发、网络链路带宽临时增大、服务器分支节点延迟增加等问题。保障用户的服务持续稳定。
            </SubTitleWhiteBox>
            <SubTitleEnBox
              variant="subtitle1"
              gutterBottom
              sx={{
                textAlign: {xs: 'center', md: 'left'}
              }}
            >
              Provide urban microenvironment services with high spatial and temporal resolution that can be
              operationalized. To provide global long-term prediction services on a larger spatial time scale.
              Through years of experience, our system is considered and developed at the beginning of design
              with the ability to cope with the problems in severe weather forms: missing observation data,
              increased frequency of model output, multiplication of computational load, high concurrency of
              service requests, temporary increase of network link bandwidth, and increased delay of server branch
              nodes. To ensure the continuous and stable service for users.
            </SubTitleEnBox>
          </Grid>
        </Grid>
        <Grid container spacing={{
          xs: 2,
          md: 4,
          lg: 6,
        }}>
          {
            serviceFeature.map((item, index) => {
              return (
                <Grid
                  item
                  xs={6}
                  md={3}
                  key={'server-2-' + index}
                >
                  <ServiceItem>
                    <div className="img" style={{ backgroundImage: `url(${item.img})` }} />
                    <SubTitleWhiteBox
                      variant="subtitle1"
                      gutterBottom
                      textAlign="center"
                      sx={{
                        textAlign: {xs: 'center', md: 'left'}
                      }}>
                      {
                        item.title
                      }
                    </SubTitleWhiteBox>
                    <SubTitleEnBox className="div">
                      {item.titleEn}
                    </SubTitleEnBox>
                  </ServiceItem>
                </Grid>
              )
            })
          }
        </Grid>
      </Container>
      {/* Box7 */}
      <Grid
        container
        spacing={2}
        alignItems="center"
        sx={{
          pt: {
            xs: 4,
            md: 8,
            lg: 16,
          },
          pb: {
            xs: 4,
            md: 8,
            lg: 16,
          },
        }}
      >
        <Grid
          item
          xs={9}
          md={6}
        >
          <img width="100%" alt="" src={require('../images/index/b7.png').default} />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
        >
          <Box sx={{
            p: 2,
            overflow: 'hidden',
            maxWidth: '680px'
          }}>
            <Box4Title
              variant="h4"
            >
              确保业务连续性
            </Box4Title>
            <Box4Title
              variant="h4"
              sx={{mb: 2}}
            >
              Ensure Business Continuity
            </Box4Title>
            <SubTitleWhiteBox
              variant="subtitle1"
              gutterBottom
              sx={{
              }}>
              我们相信好的服务可以无缝的进入用户原本的业务流程中，确保业务的连续性的同时通过将地理空间、
              气象等多方数据的组合，增加更多的参考维度，为决策提供更多元的信息。
            </SubTitleWhiteBox>
            <SubTitleEnBox
              variant="subtitle1"
              gutterBottom
              sx={{
              }}
            >
              We believe that good services can be seamlessly integrated into the user's original
              business processes, ensuring business continuity while adding more reference dimensions
              to the original decision-making process by combining geospatial, meteorological and other
              multi-dimensional data to provide more insight.
            </SubTitleEnBox>
          </Box>
        </Grid>
      </Grid>
      {/* Box8 */}
      <Container
        maxWidth="xl"
        sx={{
          pt: {
            xs: 4,
            md: 8,
            lg: 16,
          },
          pb: {
            xs: 4,
            md: 8,
            lg: 16,
          },
        }}
      >
        <Grid
          container
          spacing={3}
          alignItems="center"
          sx={{
            flexDirection: {
              xs: 'column',
              md: 'row-reverse',
            }
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
          >
            <img alt="" width="100%" src={require('../images/index/b8.png').default} />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
          >
            <Box sx={{overflow: 'hidden'}}>
              <Box4Title
                variant="h4"
              >
                应对突发事件
              </Box4Title>
              <Box4Title
                variant="h4"
                sx={{mb: 2}}
              >
                Responding to Disruptions Related to Disaster<br />
                Prevention and Mitigation
              </Box4Title>
              <SubTitleWhiteBox
                variant="subtitle1"
                gutterBottom
              >
                通过更符合用户习惯的方式，通过微信机器人等方法，在不需要登录系统的前提下，
                通过主动推送的方式将突发事件的一手服务告知用户，并通过多年的经验及技术手段保障在临灾时，
                我们的服务持续稳定。为用户提早识别风险，减轻与天气相关的人员及财产风险。
              </SubTitleWhiteBox>
              <SubTitleEnBox
                variant="subtitle1"
                gutterBottom
                sx={{
                }}
              >
                Through methods more in line with user habits, such as through WeChat bots,
                we inform users of first-hand services for emergencies through active pushing
                without the need to log into the system, and guarantee the continued stability
                of our services in the face of disasters through years of experience and technical
                approaches. Early identification of risks for users and mitigation of
                weather-related risks to people and property.
              </SubTitleEnBox>
            </Box>
          </Grid>
        </Grid>
      </Container>
      {/* Box9 */}
      <Container
        maxWidth="xl"
        sx={{
          pt: {
            xs: 4,
            md: 8,
            lg: 16,
          },
          pb: {
            xs: 4,
            md: 8,
            lg: 16,
          },
        }}
      >
        <Grid
          container
          spacing={4}
          alignItems="center"
          sx={{
            flexDirection: {
              xs: 'column',
              md: 'row',
            }
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
          >
            <img alt="" width="100%" src={require('../images/index/b9.jpg').default} />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
          >
            <Box sx={{overflow: 'hidden'}}>
              <Box4Title
                variant="h4"
              >
                为用户获取更多的时间，提早应对天气的影响
              </Box4Title>
              <Box4Title
                variant="h4"
                sx={{mb: 2}}
              >
                Gain more time for users to respond to weather impact earlier
              </Box4Title>
              <SubTitleWhiteBox
                variant="subtitle1"
                gutterBottom
                sx={{
                }}
              >
                获得最新的、针对特定地点的天气服务，提早了解各类天气灾害的可能性。为用户赢得更多时间进行准备。
                无论是突发天气事件，针对防灾减灾的准备。还是针对一年后针对气候所影响的供需关系。
                我们的服务将在波动影响的早期提供提示及后续的持续追踪。
              </SubTitleWhiteBox>
              <SubTitleEnBox
                variant="subtitle1"
                gutterBottom
              >
                Get the most up-to-date, location-specific weather services and learn about the potential
                for all types of weather hazards early. Gain more time for users to prepare.
                Whether it's an unexpected weather event, preparing for disaster preparedness
                and mitigation. Or for the supply and demand that will be affected by the weather a year from now.
                Our service will provide alerts early in the impact of fluctuations and follow up with ongoing tracking.
              </SubTitleEnBox>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export default IndexPage


const SimulationBox = styled('iframe')(({theme}) => ({
  position: 'absolute',
  top: 0,
  zIndex: 10,
  border: 'none',
  width: '100%',
  height: '56.25vw',
  verticalAlign: 'bottom',
  background: 'none',
}));
