export const seoTitle = '首页 | 赑玄阁 ';
export const seoDescription = '深圳市赑玄阁科技有限公司成立于2015年, 由中科院深圳先进技术研究院、深圳市气象局、 深圳市科创委联合孵化，是专业从事城市防灾减灾大数据分析、数据可视化、数据挖掘的方案型创新型企业。';
export const browserTitle = '首页 | 赑玄阁';
export const keywords = '赑玄阁, 气象服务, 模拟风, 防灾减灾, 天气模拟, 天气预测';

export const serviceData = [
  {
    title: '总服务次数',
    titleEn: 'Total number of services (times)',
    count: '400,000,000+',
  },
  {
    title: '每日处理数量',
    titleEn: 'Number of processes per day',
    count: '700+GB',
  },
  {
    title: '每月微信定制服务次数',
    titleEn: 'WeChat monthly customization service (times)',
    count: '360,000+',
  },
  {
    title: '每月发布的订阅次数',
    titleEn: 'Unique subscriptions issued per month (times)',
    count: '90,000+',
  },
]


export const serviceFeature = [
  {
    img: require('../images/index/service/0.png').default,
    title: '提供全球的精准服务',
    titleEn: 'Providing Global and Accurate Services',
  },
  {
    img: require('../images/index/service/1.png').default,
    title: '获取全球最新的模式与数据',
    titleEn: 'Access to the Latest Global Ensemble Models and Data',
  },
  {
    img: require('../images/index/service/2.png').default,
    title: '通过AI算法提升运算性能',
    titleEn: 'Improved Computing Performance through AI Algorithms',
  },
  {
    img: require('../images/index/service/3.png').default,
    title: '高速高性能的服务架构',
    titleEn: 'High-speed, High-performance Service Architecture',
  },
  {
    img: require('../images/index/service/4.png').default,
    title: '提升空间分辨率至2.5米',
    titleEn: 'Enhanced Spatial Resolution up to 2.5 Meter',
  },
  {
    img: require('../images/index/service/5.png').default,
    title: '提升时间分辨率',
    titleEn: 'Enhanced Time Resolution',
  },
  {
    img: require('../images/index/service/6.png').default,
    title: '不断在进化的预报精准度',
    titleEn: 'Evolving Forecast Accuracy',
  },
  {
    img: require('../images/index/service/7.png').default,
    title: '面向业务化的服务',
    titleEn: 'Business-oriented Services',
  },
]
